<template>
  <v-card flat tile class="flexcard" height="100%">
    <v-card-title class="pb-2" style="padding-left: 15px"
      >{{ isEditMode ? $t("editProject") : $t("createProject") }}<v-spacer />
      <v-btn
        class="my-auto"
        aria-label="cross"
        icon
        @click.native="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0 grow">
      <v-container class="px-4 pt-4 mb-16">
        <v-row no-gutters>
          <v-text-field
            v-model="projectName"
            outlined
            dense
            required
            :error-messages="projectNameErrors"
            @input="$v.projectName.$touch()"
            @blur="$v.projectName.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("projectName") }}
            </template></v-text-field
          >
        </v-row>
        <v-row no-gutters>
          <v-textarea
            v-model="projectDescription"
            rows="2"
            outlined
            row-height="20"
            no-resize
            dense
            required
            :error-messages="projectDescriptionErrors"
            @input="$v.projectDescription.$touch()"
            @blur="$v.projectDescription.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("description") }}
            </template></v-textarea
          >
        </v-row>
        <v-row no-gutters class="mt-2 mb-3">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
            >{{ $t("scheduleProject") }}</span
          ></v-row
        >
        <v-row no-gutters justify="center">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="scheduledStartDate"
                persistent-hint
                outlined
                dense
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                :error-messages="dateFormattedErrors"
                :hide-details="dateFormattedErrors.length == 0"
                v-on="on"
                @input="$v.scheduledStartDate.$touch()"
                @blur="$v.scheduledStartDate.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span>
                  {{ $t("startDate") }}
                </template></v-text-field
              >
            </template>
            <v-date-picker
              v-model="date"
              no-title
              :max="
                this.projectDetails &&
                this.projectDetails.min_task_start_date &&
                this.projectDetails.min_task_start_date !== null
                  ? minEndDate
                  : ''
              "
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-row no-gutters>
          <span
            class="mt-1 ml-2 caption font-weight-regular grey--text text--darken-2"
            >{{ $t("projectStartDateLabel") }}</span
          ></v-row
        >
        <v-row no-gutters class="mt-5 mb-3">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
            >{{ $t("attachFiles") }}</span
          ></v-row
        >
        <FileAttachments ref="fileAttachments" />
      </v-container>
    </v-card-text>

    <v-divider class="my-2 mx-4"></v-divider>

    <v-card-actions class="mx-2 mb-2">
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        outlined
        class="mr-2 text-none rounded-lg elevation-0"
        width="90"
        @click="closeDialog"
      >
        {{ $t("cancel") }}
      </v-btn>

      <v-btn
        v-if="!isEditMode"
        color="primary"
        width="160"
        depressed
        class="text-none rounded-lg elevation-0"
        :disabled="$v.$invalid || scheduledButtonClicked"
        @click="scheduleProjectClicked"
        ><span class="white--text text-none">{{ $t("scheduleProject") }}</span>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="text-none font-weight-regular grey--text rounded-lg elevation-0"
        width="90"
        depressed
        :disabled="$v.$invalid || updateButtonClicked"
        @click="updateProject"
        ><span class="white--text text-none">{{ $t("update") }}</span>
      </v-btn>
    </v-card-actions>
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown"
      fixed
      horizontal
      height="70"
      class="px-2 py-4"
      ><v-row justify="space-between" no-gutters>
        <v-btn
          color="primary"
          outlined
          class="mr-2 text-none rounded-lg elevation-0"
          width="50%"
          @click="closeDialog"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-if="!isEditMode"
          color="primary"
          class="text-none font-weight-regular grey--text rounded-lg elevation-0"
          width="50%"
          depressed
          :disabled="$v.$invalid || scheduledButtonClicked"
          @click="scheduleProjectClicked"
          ><span class="white--text text-none">{{
            $t("scheduleProject")
          }}</span>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          class="text-none font-weight-regular grey--text rounded-lg elevation-0"
          width="50%"
          depressed
          :disabled="$v.$invalid || updateButtonClicked"
          @click="updateProject"
          ><span class="white--text text-none">{{ $t("update") }}</span>
        </v-btn>
      </v-row></v-bottom-navigation
    >
    <v-overlay
      v-model="projectOverlay"
      contained
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { bus } from "@/main";

export default {
  name: "AddProject",
  mixins: [validationMixin],
  validations() {
    return {
      scheduledStartDate: { required },
      projectDescription: { required },
      projectName: { required },
    };
  },
  data() {
    return {
      projectDescription: "",
      projectName: "",
      menu1: false,
      scheduledStartDate: "",
      date: "",
      scheduledButtonClicked: false,
      updateButtonClicked: false,
      projectOverlay: false,
    };
  },
  components: {
    FileAttachments: () => import("./FileAttachments.vue"),
  },
  props: {
    projectDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async getProjectData() {
      const projectDataResponse =
        await this.$store.dispatch("getCompanyProject");
      if (projectDataResponse.status !== "error") {
        if (projectDataResponse && projectDataResponse.length !== 0) {
          projectDataResponse.forEach((item) => {
            item.progress = 35;
            item.tasks = 4;
            item.attachments = 3;
            if (item.status === null) item.status = "Planning";
          });
          this.$store.commit("setProjectData", projectDataResponse);
        }
      }
    },
    closeDialog() {
      this.projectDescription = "";
      this.projectName = "";
      this.menu1 = false;
      this.scheduledStartDate = "";
      this.date = "";
      this.$emit("hideProjectDialog");
    },
    async scheduleProjectClicked() {
      // Post API call
      this.scheduledButtonClicked = true;
      this.projectOverlay = true;
      const projectDetails = {
        project_name: this.projectName,
        description: this.projectDescription,
        start_date: this.date,
      };
      const projetStatus = await this.$store.dispatch(
        "createProject",
        projectDetails,
      );
      if (projetStatus.status !== "error") {
        if (this.$refs.fileAttachments.fileInfo) {
          await this.uploadFiles(
            this.$refs.fileAttachments.fileInfo,
            projetStatus.createResult[0].project_id,
          );
        }
      }
      await this.getProjectData();
      this.$emit("projectAdded");
      this.scheduledButtonClicked = false;
      this.projectOverlay = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return month ? `${month}/${day}/${year}` : date;
    },
    async updateProject() {
      this.updateButtonClicked = true;
      this.projectOverlay = true;
      const projectDetails = {
        project_id: this.projectDetails.project_id,
        project_name: this.projectName,
        description: this.projectDescription,
        start_date: this.date,
      };
      const updateProjectResp = await this.$store.dispatch(
        "updateProject",
        projectDetails,
      );
      if (updateProjectResp.status === "error") {
        console.log("error in project update");
      } else {
        if (this.$refs.fileAttachments.fileInfo) {
          await this.uploadFiles(
            this.$refs.fileAttachments.fileInfo,
            this.projectDetails.project_id,
          );
        }
        //this.$emit("projectUpdated", projectDetails);
        this.$emit("hideProjectDialog");
        bus.$emit("projectUpdated");
      }
      this.updateButtonClicked = false;
      this.projectOverlay = false;
    },
    async uploadFiles(files, projectId) {
      const tokenResp = await this.$store.dispatch(
        "getAuthTokenForAttachments",
      );
      let token;
      if (tokenResp.status !== "error") {
        token = tokenResp.token;
      }
      const uploadedFileDetails = [];
      try {
        for (const file of files) {
          const payload = {};
          payload.file = file;
          payload.companyId = this.$store.state.User.user.company_id;
          payload.token = token;
          const uploadFileStatus = await this.$store.dispatch(
            "uploadImages",
            payload,
          );
          if (uploadFileStatus.status !== "error") {
            uploadedFileDetails.push(uploadFileStatus[0]);
          } else {
            //this.uploadError = true;
          }
        }
        if (uploadedFileDetails.length) {
          const payload = {
            attachments: uploadedFileDetails.map((item) => {
              return Object.assign({}, item, {
                reference_id: projectId,
                reference_type: "project",
                caption: this.caption,
                comments: this.comments,
              });
            }),
          };
          const saveAttachments = await this.$store.dispatch(
            "saveAttachmentData",
            payload,
          );
          if (saveAttachments.status !== "error") {
            bus.$emit("projectFileUploaded", saveAttachments);
          } else {
            console.log("error in saving attachments");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    minEndDate() {
      var tempBeginDate = new Date(
        this.projectDetails &&
        this.projectDetails.min_task_start_date &&
        this.projectDetails.min_task_start_date !== null
          ? this.projectDetails.min_task_start_date
          : "",
      );
      var beginDate = new Date(tempBeginDate.setDate(tempBeginDate.getDate()));
      return beginDate.toISOString().slice(0, 10);
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.scheduledStartDate.$dirty) return errors;
      !this.$v.scheduledStartDate.required &&
        errors.push(this.$t("projectStartDateRequired"));
      return errors;
    },
    projectNameErrors() {
      const errors = [];
      if (!this.$v.projectName.$dirty) return errors;
      !this.$v.projectName.required &&
        errors.push(this.$t("projectNameRequired"));
      return errors;
    },
    projectDescriptionErrors() {
      const errors = [];
      if (!this.$v.projectDescription.$dirty) return errors;
      !this.$v.projectDescription.required &&
        errors.push(this.$t("projectDescriptionRequired"));
      return errors;
    },
    isEditMode() {
      return this.projectDetails && Object.keys(this.projectDetails).length > 0;
    },
  },
  watch: {
    date(val) {
      this.scheduledStartDate = this.formatDate(this.date);
    },
  },
  created() {
    if (this.projectDetails && Object.keys(this.projectDetails).length > 0) {
      this.projectDescription = this.projectDetails.description;
      this.projectName = this.projectDetails.project_name;
      const [month, day, year] = this.projectDetails.start_date.split("/");
      this.date = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
};
</script>

<style lang="scss">
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
