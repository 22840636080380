var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "flexcard", attrs: { flat: "", tile: "", height: "100%" } },
    [
      _c(
        "v-card-title",
        { staticClass: "pb-2", staticStyle: { "padding-left": "15px" } },
        [
          _vm._v(
            _vm._s(
              _vm.isEditMode ? _vm.$t("editProject") : _vm.$t("createProject")
            )
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "my-auto",
              attrs: { "aria-label": "cross", icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "pa-0 grow" },
        [
          _c(
            "v-container",
            { staticClass: "px-4 pt-4 mb-16" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      "error-messages": _vm.projectNameErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.projectName.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.projectName.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("projectName")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.projectName,
                      callback: function ($$v) {
                        _vm.projectName = $$v
                      },
                      expression: "projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      rows: "2",
                      outlined: "",
                      "row-height": "20",
                      "no-resize": "",
                      dense: "",
                      required: "",
                      "error-messages": _vm.projectDescriptionErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.projectDescription.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.projectDescription.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" *"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.$t("description")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.projectDescription,
                      callback: function ($$v) {
                        _vm.projectDescription = $$v
                      },
                      expression: "projectDescription",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-2 mb-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("scheduleProject")))]
                  ),
                ]
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "menu1",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "persistent-hint": "",
                                        outlined: "",
                                        dense: "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        "error-messages":
                                          _vm.dateFormattedErrors,
                                        "hide-details":
                                          _vm.dateFormattedErrors.length == 0,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.scheduledStartDate.$touch()
                                        },
                                        blur: function ($event) {
                                          return _vm.$v.scheduledStartDate.$touch()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [_vm._v(" *")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("startDate")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.scheduledStartDate,
                                        callback: function ($$v) {
                                          _vm.scheduledStartDate = $$v
                                        },
                                        expression: "scheduledStartDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu1,
                        callback: function ($$v) {
                          _vm.menu1 = $$v
                        },
                        expression: "menu1",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          max:
                            this.projectDetails &&
                            this.projectDetails.min_task_start_date &&
                            this.projectDetails.min_task_start_date !== null
                              ? _vm.minEndDate
                              : "",
                        },
                        on: {
                          input: function ($event) {
                            _vm.menu1 = false
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "mt-1 ml-2 caption font-weight-regular grey--text text--darken-2",
                  },
                  [_vm._v(_vm._s(_vm.$t("projectStartDateLabel")))]
                ),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-5 mb-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("attachFiles")))]
                  ),
                ]
              ),
              _c("FileAttachments", { ref: "fileAttachments" }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-2 mx-4" }),
      _c(
        "v-card-actions",
        { staticClass: "mx-2 mb-2" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mr-2 text-none rounded-lg elevation-0",
              attrs: { color: "primary", outlined: "", width: "90" },
              on: { click: _vm.closeDialog },
            },
            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
          ),
          !_vm.isEditMode
            ? _c(
                "v-btn",
                {
                  staticClass: "text-none rounded-lg elevation-0",
                  attrs: {
                    color: "primary",
                    width: "160",
                    depressed: "",
                    disabled: _vm.$v.$invalid || _vm.scheduledButtonClicked,
                  },
                  on: { click: _vm.scheduleProjectClicked },
                },
                [
                  _c("span", { staticClass: "white--text text-none" }, [
                    _vm._v(_vm._s(_vm.$t("scheduleProject"))),
                  ]),
                ]
              )
            : _c(
                "v-btn",
                {
                  staticClass:
                    "text-none font-weight-regular grey--text rounded-lg elevation-0",
                  attrs: {
                    color: "primary",
                    width: "90",
                    depressed: "",
                    disabled: _vm.$v.$invalid || _vm.updateButtonClicked,
                  },
                  on: { click: _vm.updateProject },
                },
                [
                  _c("span", { staticClass: "white--text text-none" }, [
                    _vm._v(_vm._s(_vm.$t("update"))),
                  ]),
                ]
              ),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "px-2 py-4",
              attrs: { fixed: "", horizontal: "", height: "70" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", "no-gutters": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 text-none rounded-lg elevation-0",
                      attrs: { color: "primary", outlined: "", width: "50%" },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  !_vm.isEditMode
                    ? _c(
                        "v-btn",
                        {
                          staticClass:
                            "text-none font-weight-regular grey--text rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            width: "50%",
                            depressed: "",
                            disabled:
                              _vm.$v.$invalid || _vm.scheduledButtonClicked,
                          },
                          on: { click: _vm.scheduleProjectClicked },
                        },
                        [
                          _c("span", { staticClass: "white--text text-none" }, [
                            _vm._v(_vm._s(_vm.$t("scheduleProject"))),
                          ]),
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass:
                            "text-none font-weight-regular grey--text rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            width: "50%",
                            depressed: "",
                            disabled:
                              _vm.$v.$invalid || _vm.updateButtonClicked,
                          },
                          on: { click: _vm.updateProject },
                        },
                        [
                          _c("span", { staticClass: "white--text text-none" }, [
                            _vm._v(_vm._s(_vm.$t("update"))),
                          ]),
                        ]
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-overlay",
        {
          staticClass: "align-center justify-center",
          attrs: { contained: "" },
          model: {
            value: _vm.projectOverlay,
            callback: function ($$v) {
              _vm.projectOverlay = $$v
            },
            expression: "projectOverlay",
          },
        },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }